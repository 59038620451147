import React, { Component } from "react";
import {
  FormGroup,
  FormControl,
  Grid,
  Button,
  Container,
  Row,
  Col
} from "react-bootstrap";
import Card from "../components/Card/Card";
import StandardCard from "../components/StandardCard";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./Standards.css";
import { API } from "aws-amplify";
import {
  s3Upload,
  s3RawUpload,
  CreateFileName,
  VideoUpload
} from "../libs/awsLib";
import VideoDisplay from "../components/VideoDisplay";
import TimePicker from "rc-time-picker";
import { format } from "path";

export default class NewStandard extends Component {
  constructor(props) {
    super(props);

    this.dataFiles = null;
    this.VideoFile = null;
    this.state = {
      isLoading: null,
      StandardName: "",
      value: "",
      unit: "",
      type: "",
      icon: "",
      testUnit: ""
    };
  }

  validateForm() {
    return this.state.StandardName.length > 0;
  }

  handleChange = event => {
    var name = event.target.id;
    if (event.target.id == "") {
      name = "type"
    }
    console.log([name], event.target.value)
    this.setState({
      [name]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    console.log(this.state.StandardName);
    try {
      console.log("Wrote to DB");
      await this.createNewStandard({
        Name: this.state.StandardName,
        EquationType: this.state.type,
        Value: this.state.value,
        Unit: this.state.unit,
        Icon: this.state.icon
      });
      this.props.parentCallback();
      this.state.isLoading = false;
    } catch (e) {
      alert(e);
      console.log(e);
      this.setState({ isLoading: false });
    }
  };

  builtInStandards() {
    var standards = [];
    standards.push({
      Name: "Test Standard",
      label: "Test Standard - Greater than 135",
      Value: 135,
      Type: "Greater",
      Unit: "Random"
    });
    standards.push({
      Name: "Heart Rate Standard",
      label: "HR Standard - Greater than 110",
      Value: 110,
      Type: "Greater",
      Unit: "BPM"
    });
    standards.push({
      Name: "Particulate Standard",
      label: "Particulate Standard - Greater than 7000",
      Value: 7000,
      Type: "Greater",
      Unit: "p/L"
    });
    standards.push({
      Name: "CO2 Standard",
      label: "CO2 Standard - Greater than 5000",
      Value: 5000,
      Type: "Greater",
      Unit: "PPM"
    });

    return standards;
  }

  async componentDidMount() {
    try {
      this.setState({});
    } catch (e) {
      alert("here" + e);
    }
  }

  createNewStandard(standard) {
    return API.post("projects", "/standards", {
      body: standard
    });
  }

  render() {
    return (
      <div className="Standards">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="StandardName">
            <Grid >Standard Name</Grid >
            <FormControl
              autoFocus
              type="text"
              value={this.state.StandardName}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="value">
            <Grid >Value</Grid >
            <FormControl
              type="text"
              value={this.state.value}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="type">
            <Grid >Type</Grid >
            <select className="form-control" name="type" onChange={this.handleChange} value={this.state.type}>
              <option selected value="Greater">Greater</option>
              <option value="Lesser">Lesser</option>
            </select>

          </FormGroup>
          <FormGroup controlId="unit">
            <Grid >Unit</Grid >
            <FormControl
              type="text"
              value={this.state.unit}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="icon">
            <Grid>Icon</Grid>
            <FormControl
              type="text"
              value={this.state.icon}
              onChange={this.handleChange}
            />

          </FormGroup>

          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
        </form>
      </div>
    );
  }
}
