import React, { Component } from "react";
import { FormGroup, FormControl, Grid } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./NewProject.css";
import { API } from "aws-amplify";
import {
  s3Upload,
  s3RawUpload,
  CreateFileName,
  VideoUpload
} from "../libs/awsLib";
import VideoDisplay from "../components/VideoDisplay";
import TimePicker from "rc-time-picker";
import { format } from "path";

export default class NewNote extends Component {
  constructor(props) {
    super(props);

    this.dataFiles = null;
    this.VideoFile = null;
    this.state = {
      isLoading: null,
      projectName: "",
      VideoStartTime: ""
    };
  }

  validateForm() {
    return this.state.projectName.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleDataFileChange = event => {
    console.log("DATA FILES");
    this.dataFiles = event.target.files[0];
    console.log(this.dataFiles);
  };

  uploadVideoFiles() {
    console.log("Upload VIdeo");
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    var ourAttachment = null;
    if (this.dataFiles) {
      console.log(this.dataFiles);
      ourAttachment = await s3Upload(this.dataFiles);
      console.log(ourAttachment);

      var ourFile = "";
      try {
        console.log("Wrote to DB");
        await this.createProject({
          Name: this.state.projectName,
          OriginalFile: ourAttachment
        });
        this.props.parentCallback();
        this.state.isLoading = false;
      } catch (e) {
        alert(e);
        console.log(e);
        this.setState({ isLoading: false });
      }
    }
  };
  createDataContainer(datacontainers) {
    return API.post("projects", "/datacontainers", {
      body: datacontainers
    });
  }

  createNewProject(projectID, fileLocation, name) {
    var ourProject = {
      ProjectID: projectID,
      FileLocation: fileLocation,
      Name: name
    };
    return API.post("projects", "/CreateNewProject-SQS", {
      body: ourProject
    });
  }

  CreateVideo(video) {
    return API.post("projects", "/video", {
      body: video
    });
  }

  fakeSubmitt = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
  };

  formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }

  createProject(project) {
    return API.post("projects", "/vemcaptureProjects", {
      body: project
    });
  }

  render() {
    return (
      <div className="NewProject">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="projectName" bsSize="large">
            <Grid >Project's Name</Grid >
            <FormControl
              autoFocus
              type="text"
              value={this.state.projectName}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="dataFiles">
            <Grid >Files</Grid >
            <FormControl
              onChange={this.handleDataFileChange}
              type="file"
              multiple
            />
          </FormGroup>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
        </form>
      </div>
    );
  }
}
