import React, { Component } from 'react';
import Pdf from '../assets/pdfs/CapabilityStatement.pdf';
import { Document, Page } from 'react-pdf';
class Download extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
  }
 
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }
  render() {
    const { pageNumber, numPages } = this.state;
 
    return (
      <div>
        <Document
          file={"https://my.chevrolet.com/content/dam/gmownercenter/gmna/dynamic/manuals/1999/chevrolet/blazer/1999_chevrolet_blazer_owners.pdf"}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <p>Page {pageNumber} of {numPages}</p>
      </div>
    );
  }
}

export default Download;