import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";
import SideBar from "./components/Sidebar/Sidebar";
import AdminNavbar from "./components/Navbars/AdminNavbar";
import Home from "./containers/Home";
import "./App.css";
import Routes from "./Routes";
import pdf from "../src/assets/pdfs/CapabilityStatement.pdf";
import Settings from "./containers/Settings";
import Devices from "./containers/Devices";
import Standards from "./containers/Standards";
import NewProject from "./containers/NewProject";
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      lastSecureRedirectAttempt: null,
      fullName: ""
    };
  }

  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  };

  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    this.props.history.push("/login");
  };

  redirectToSecure() {
    var location = window.location;
    console.log(location.host)
    //Todo: Replace this with Dev Environment
    if (location.protocol !== 'https:' && location.host != "localhost:3000") {
      console.log("Redirecting to Secure Location")
      location.replace(`https:${location.href.substring(location.protocol.length)}`);
    }
  }
  componentWillMount() {
    this.redirectToSecure()
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };
    const dashboardRoutes = [
      {
        path: "/Home",
        name: "Home",
        icon: "pe-7s-graph",
        component: Home,
        layout: ""
      },
      {
        path: "/settings",
        name: "Settings",
        icon: "pe-7s-graph",
        component: Settings,
        layout: ""
      }
    ];
    var navBarClassName = "";
    var urlPage = window.location.href;
    if (this.state.isAuthenticated && !(urlPage.includes("project"))) {
      navBarClassName = "Nothing";
    }


    return (
      !this.state.isAuthenticating && (
        <div className={navBarClassName}>
          <Navbar fluid bg="dark" color="dark">
            <Navbar.Header>
              <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
              <Nav pullLeft>
                <LinkContainer to="/">
                  <NavItem>Home</NavItem>
                </LinkContainer>
              </Nav>
              <Nav pullRight>
                {this.state.isAuthenticated && !(urlPage.includes("project")) ? (
                  <NavItem onClick={this.handleLogout}>Logout</NavItem>
                ) : (
                  <Fragment>
                    <LinkContainer to="/signup">
                      <NavItem>Signup</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/login">
                      <NavItem>Login</NavItem>
                    </LinkContainer>
                  </Fragment>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          {this.state.isAuthenticated && !(urlPage.includes("project")) ? (
            <SideBar {...this.props} routes={dashboardRoutes} />
          ) : (
            <p />
          )}
          <div className="innerScreen">
            <Routes childProps={childProps} />
          </div>
        </div>
      )
    );
  }
}

export default withRouter(App);
