import React, { Component } from "react";
import {
  FormGroup,
  FormControl,
  Grid,
  Button,
  Row,
  Col,
  Modal
} from "react-bootstrap";
import Card from "../components/Card/Card";
import StandardCard from "../components/StandardCard";
import LoaderButton from "../components/LoaderButton";
import { Redirect } from "react-router-dom";
import config from "../config";
import "./Standards.css";
import { API } from "aws-amplify";
import {
  s3Upload,
  s3RawUpload,
  CreateFileName,
  VideoUpload
} from "../libs/awsLib";
import VideoDisplay from "../components/VideoDisplay";
import TimePicker from "rc-time-picker";
import { format } from "path";

export default class NewDevice extends Component {
  constructor(props) {
    super(props);

    this.dataFiles = null;
    this.VideoFile = null;
    this.PleaseShow = this.props.show;
    this.state = {
      isLoading: null,
      DeviceName: "",
      Owner: "",
      show: this.props.show,
      redirect: false,
      maybe: true
    };
  }

  setRedirect = () => {
    this.setState({
      redirect: true
    });
  };
  renderRedirect = () => {
    console.log("here");
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };

  validateForm() {
    return this.state.DeviceName.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  callParent() {
    this.props.parentCallback();
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
    try {
      console.log("Wrote to DB");
      await this.createNewDevice({
        DeviceName: this.state.DeviceName,
        Owner: this.state.Owner
      });
      this.state.isLoading = false;
      this.callParent();
      // alert("Done uploading. Please Refresh Page");
    } catch (e) {
      alert(e);
      console.log(e);
      this.setState({ isLoading: false });
    }
  };

  async componentDidMount() {
    try {
      this.setState({});
    } catch (e) {
      alert("here" + e);
    }
  }

  createNewDevice(device) {
    return API.post("projects", "/devices", {
      body: device
    });
  }

  updateModalShowState(stuff) { }

  newRender() {
    return (
      <>
        <Modal show={this.props.show} onHide={() => this.props.onClose}>
          <Modal.Header closeButton>
            <Modal.Title>Register a new Device</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.oldRender()}</Modal.Body>
        </Modal>
      </>
    );
  }
  render() {
    var output = this.oldRender();
    return <React.Fragment>{output}</React.Fragment>;
  }

  oldRender() {
    return (
      <div className="Standards">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="DeviceName">
            <FormControl
              autoFocus
              type="text"
              value={this.state.DeviceName}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="Owner">
            <FormControl
              type="text"
              value={this.state.Owner}
              onChange={this.handleChange}
            />
          </FormGroup>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
        </form>
      </div>
    );
  }
}
