import { Line } from "react-chartjs-2";
import React, { Component } from "react";
import "chartjs-plugin-annotation";
import { defaults } from "react-chartjs-2";
export class DataDisplay extends Component {
  retrieveString() {
    return "hello";
  }

  render() {
    var currentIndex = 0;
    defaults.global.animation = false;
    var content = "Current Point";

    var index = this.props.dataContainer.labels.indexOf(
      this.props.lineListener
    );
    if (index !== -1) {
      try {
        content = this.props.dataContainer.data[index].data.toFixed(3);
      } catch (e) { }
    }

    const options = {
      annotation: {
        annotations: [
          {
            type: "line",
            id: "call-count-1",
            mode: "vertical",
            scaleID: "x-axis-0",
            value: this.props.lineListener,
            borderColor: "blue",
            label: {
              backgroundColor: "rgba(0,0,0,0.8)",
              fontFamily: "sans-serif",
              fontSize: 12,
              fontStyle: "bold",
              fontColor: "#fff",
              xPadding: 12,
              yPadding: 6,
              cornerRadius: 4,
              position: "center",
              yAdjust: -100,
              enabled: true,
              content: content
            }
          }
        ]
      },
      maintainAspectRatio: true
    };

    return (
      <React.Fragment>

        <Line
          data={this.props.data}
          width={100}
          height={25}
          options={options}
          redraw={true}
          animation={true}
        />

      </React.Fragment>
    );
  }
}

export default DataDisplay;
