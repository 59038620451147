import React, { Component } from "react";

import index, { API, Storage } from "aws-amplify";
import {
  Button,
  Grid,
  Row,
  Col
} from "react-bootstrap";
import "./Project.css";
import Select from "react-select";
import DataDisplay from "../components/DataDisplay";
import ReactPlayer from "react-player";
import Card from "../components/Card/Card";
import Moment, { relativeTimeThreshold } from "moment";
import BrokenStandardText from "../components/BrokenStandardText";
import { ProjectStandardScreen } from "../components/ProjectComponents/ProjectStandardScreen";
import { TabbedCharts } from "../components/ProjectComponents/tabbedCharts"
import SensorBar from "../components/ProjectComponents/sensorBar"
import FloatingReactPlayer from "../components/ProjectComponents/FloatingReactPlayer"
import { afterDatasetUpdate } from "chartjs-plugin-annotation";
import { getAllSesssions, getSesssion, convertStringJsonToObject } from "../services/projectService";


export default class Notes extends Component {
  constructor(props) {
    super(props);
    
    this.file = null;
    this.dataManagement = {}
    this.state = {
      lagTime: 5,
      isLoading: null,
      playbackTimer: setInterval(this.tick.bind(this), 1000),
      filterStandard: true,
      isInitalLoading: true,
      isDeleting: null,
      project: null,
      content: "",
      attachmentURL: null,
      Name: "",
      isPlaying: false,
      currentTime: null,
      dataContainers: null,
      startLastRecordedTime: null,
      currentDataPoints: null,
      startTime: null,
      selectedStandards: null,
      highlights: null,
      currentHighlight: null,
      lastHighLightIndex: -1,
      videoPlayers: null,
      tempPlayer: null,
      chartCurrentTime: null,
      videoIsLoaded: false,
      player: React.createRef(),
      lastReportedTime: "",
      nextHighlight: false,
      skipToNextHighlight: false,
      projectId: this.props.match.params.id,
      recreateHighlights: true
    };
  }

  findCurrentHighLight(currentTime) {
    if (this.state.highlights == null) {
      // console.log("Returned Null");
      return null;
    }
    for (var i = 0; i < this.state.highlights.length; i++) {
      var startTime = new Date(this.state.highlights[i].startTime);
      var endTime = new Date(this.state.highlights[i].endTime);
      if (currentTime >= startTime && this.state.currentHighlight <= endTime) {
        this.state.lastHighLightIndex = i;
        return this.state.highlights[i];
      }
    }
    return "";
  }

  flagForCalculateHighlights(data, value) {
    this.dataManagement[data] = value
    this.setState({
      recreateHighlights: true,

    })
  }

  createRowForHighlightCreation(dataContainer) {
    // console.log(dataContainer.name)
    this.dataManagement[dataContainer.name] = 0
    return (
      <React.Fragment key={dataContainer.name}>
        <input key={dataContainer.name} className="quantity" name="quantity" value={this.dataManagement[dataContainer.name]} onChange={() => this.flagForCalculateHighlights(dataContainer.name, this.dataManagement[dataContainer.name]++)} type="number" />
      </React.Fragment >);
  }
  renderHighlightContent() {
    // console.log("DATA CONTAINERS", this.state.dataContainers)

    return (
      <React.Fragment>
        {this.state.dataContainers.map(item => (
          this.createRowForHighlightCreation(item)
        ))}
      </React.Fragment>
    )


  }

  renderHighlightEntry() {
    return (
      <React.Fragment>
        {/* <Card
          title="New Section" content={this.renderHighlightContent()}>


        </Card> */}
      </React.Fragment>);
  }

  tick() {
    // This function is called every 50 ms. It updates the
    // elapsed counter. Calling setState causes the component to be re-rendered
    //console.log(this.state)
    var realLifeTime = new Date();


    if (this.state.isPlaying) {
      var newCurrentTime = new Date(this.state.currentTime);
      var timeToAdd = realLifeTime - new Date(this.state.lastReportedTime);

      newCurrentTime.setSeconds(newCurrentTime.getSeconds() + 1);
      this.state.chartCurrentTime = Moment(newCurrentTime).format(
        "MM/DD/YYYY HH:mm:ss"
      );
      if (newCurrentTime >= this.state.endTime) {
        this.state.isPlaying = false;
      }

      if (this.state.highlights == null || this.state.highlights.length == 0) {
        this.populateCurrentDataPoints();
        this.setState({
          currentTime: Moment(newCurrentTime).format("MM/DD/YYYY HH:mm:ss")
        });
      } else {
        if (
          this.state.currentHighlight == null ||
          new Date(this.state.currentHighlight.endTime).getTime() <
          newCurrentTime ||
          this.state.skipToNextHighlight
        ) {
          this.setState({
            skipToNextHighlight: false
          });
          this.state.currentHighlight = this.findCurrentHighLight(
            newCurrentTime
          );
          if (this.state.currentHighlight == "") {
            if (
              this.state.lastHighLightIndex + 1 >=
              this.state.highlights.length
            ) {
              this.state.isPlaying = false;
            }
            this.state.currentHighlight = this.state.highlights[
              this.state.lastHighLightIndex + 1
            ];
            this.state.lastHighLightIndex = this.state.lastHighLightIndex + 1;
          }
          if (
            this.state.currentHighlight != null ||
            this.state.currentHighlight == ""
          ) {
            newCurrentTime = new Date(this.state.currentHighlight.startTime);
            this.tempMoveVideo(newCurrentTime);
          }
        }

        this.populateCurrentDataPoints();
        this.setState({
          currentTime: Moment(newCurrentTime).format("MM/DD/YYYY HH:mm:ss")
        });
      }
    }
    this.state.lastReportedTime = Moment(new Date()).format(
      "MM/DD/YYYY HH:mm:ss"
    );
  }

  handleChangeInHighlight() { }

  handleStandardChange(selectedStandards) {
    this.setState({
      selectedStandards
    });
    this.generateHighLights(selectedStandards);
  }

  highLightNewVersion() {
    this.setState({
      highlights: []
    })
    var highlightOutput = []
    for (var key in this.state.highlightGenerationData) {
      var hlData = this.state.highlightGenerationData[key];

      if (!hlData.enabled) {
        continue;
      }
      var dataContainer = this.findNewDataContainer(key)
      console.log(dataContainer)
      var newStandard = {
        "Value": hlData.value,
        "EquationType": "Greater"
      }
      console.log(newStandard)
      var highlightResults = this.NewapplyStandardToDataSet(dataContainer, newStandard)
      for (var i = 0; i < highlightResults.length; i++) {
        highlightOutput.push(highlightResults[i]);
      }
    };

    this.setState({
      highlights: highlightOutput
    })
    console.log("highlightOutput", highlightOutput)
  }

  findNewDataContainer(dataName) {
    var found = null;
    this.state.dataContainers.forEach(element => {
      if (dataName.toLowerCase() === element.name.toLowerCase()) {
        found = element;
      }
    });
    console.log("dataContainer", found)
    return found;
  }

  NewapplyStandardToDataSet(dataContainer, standard) {

    var highlights = [];
    var indexes = [];
    for (var i = 0; i < dataContainer.sensorData.length; i++) {
      if (
        this.comparePoints(
          standard.EquationType,
          parseInt(dataContainer.sensorData[i]),
          parseInt(standard.Value)
        )
      ) {
        indexes.push(i);
      }
    }
    var combined = this.combineIndexes(indexes, this.state.lagTime); 
    var converted = this.convertCombinedIndexesToTimes(dataContainer, combined);
    return converted;
  }


  generateHighLights(newStandard) {
    if (newStandard == null || newStandard.label === "No Standard") {
      this.state.highlights = null;
    } else {
      this.state.highlights = [];

      var ourContainer = this.findDataContainer(newStandard);
      if (ourContainer == null) {
        this.state.highlights = null;
        return;
      }
      var results = this.applyStandardToDataSet(ourContainer, newStandard);
      for (var i = 0; i < results.length; i++) {
        this.state.highlights.push(results[i]);
      }
    }
  }

  findDataContainer(standard) {
    var found = null;
    this.state.dataContainers.forEach(element => {
      if (standard.Unit.toLowerCase() === element.units.toLowerCase()) {
        found = element;
      }
    });
    return found;
  }

  applyStandardToDataSet(dataContainer, standard) {
    var highlights = [];
    var indexes = [];
    if (standard.Unit.toLowerCase() !== dataContainer.units.toLowerCase()) {
      return highlights;
    }
    for (var i = 0; i < dataContainer.sensorData.length; i++) {
      if (
        this.comparePoints(
          standard.EquationType,
          dataContainer.sensorData[i],
          standard.Value
        )
      ) {
        console.log("adding i ", i)
        console.log("adding againist ", standard.Value)
        indexes.push(i);
      }
    }
    var combined = this.combineIndexes(indexes, this.state.lagTime); //Todo: This is where we need to put Lag Time
    var converted = this.convertCombinedIndexesToTimes(dataContainer, combined);
    return converted;
  }

  comparePoints(mode, sensorData, valueToCheck) {
    console.log("mode", mode)
    console.log("sensorData", sensorData)
    console.log("valueToCheck", valueToCheck)
    console.log("mode == Greater", mode == "Greater")

    if (mode === "Greater") {
      return sensorData >= valueToCheck;
    } else if (mode === "Lesser") {
      return sensorData <= valueToCheck;
    } else {
      return sensorData >= valueToCheck;
    }
  }

  combineIndexes(indexes, tolerance) {
    var combinedIndex = [];
    var currentIndex = -1;
    var maxIndex = -1;
    var startIndex = -1;
    for (var point = 0; point < indexes.length; point++) {
      currentIndex = indexes[point];
      if (startIndex == -1) {
        startIndex = indexes[point];
        maxIndex = indexes[point];
      } else {
        if (maxIndex + tolerance < currentIndex) {
          combinedIndex.push({
            startPoint: startIndex,
            endIndex: maxIndex
          });
          startIndex = currentIndex;
          maxIndex = currentIndex;
        } else {
          maxIndex = currentIndex;
        }
      }
    }
    combinedIndex.push({
      startPoint: startIndex,
      endIndex: maxIndex
    });
    return combinedIndex;
  }



  convertCombinedIndexesToTimes(dataContainer, combinedIndexes) {
    var convertedIndexes = [];
    var lagTime = this.state.lagTime;
    for (var i = 0; i < combinedIndexes.length; i++) {
      var indexes = combinedIndexes[i];
      if (indexes["startPoint"] != -1 && indexes["endIndex"] != -1) {
        var laggedStartPoint = indexes["startPoint"] - lagTime;
        if (laggedStartPoint < 0) {
          laggedStartPoint = 0;
        }
        var laggedEndPoint = indexes["endIndex"] + lagTime;

        if (laggedEndPoint > indexes.length) {
          laggedEndPoint = indexes.length - 1;
        }

        var startTime = dataContainer.labels[laggedStartPoint];
        var endTime = dataContainer.labels[laggedEndPoint];
        convertedIndexes.push({
          startTime: startTime,
          endTime: endTime
        });
      }
    }
    return convertedIndexes;
  }

  isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  createGraphDisplayer(dataContainers) {
    if (dataContainers == null) {
      return
    }
    var enabledCharts = [];
    dataContainers.forEach(element => {
      enabledCharts[element.name] = true;
    });

    this.setState({
      enabledCharts
    })
  }

  createHighlightData(dataContainers) {
    console.log("Creating Highlight Data")
    if (dataContainers == null) {
      console.log("Data Containers is null")
      return
    }
    var highlightGenerationData = {};
    
    dataContainers.forEach(element => {
      console.log("element.name: " + element.name)
      highlightGenerationData[element.name] = {
        "name": element.name,
        "enabled": false,
        "mode": "greater",
        "value": 0
      };
    });

    this.setState({
      highlightGenerationData
    })
  }

  getUncompressedSensor(sensors, sensorName) {
    sensors.forEach(sensor => {
      if (sensor.name === sensorName) {
        return sensor;
      }
    })
    return null;
  }

  generateDataByDate(sensorData) {

  }

  convertSensorNameToChartName(sensorName) {
    var sensorNameSplit = sensorName.split(":");
    var type = sensorNameSplit[1];
    var sensorId = sensorNameSplit[0];
    return type;
  }

  getSensorUnit(sensorName) {
    switch (sensorName) {

      case "Temperature":
        return "°C";
      case "Humidity":
        return "%";
      case "Pressure":
        return "hPa";
      case "random":
        return "random";
      case "CO2":
        return "ppm";
      case "VOC":
        return "ppb";
    }

    if (sensorName.includes("PM")) {
      return "µg/m3";
    }

    return "unknown";

  }

  getSensorNiceName(sensorKey) {
    var sensorNameSplit = sensorKey.split(":");
    return this.getReallyNiceName(sensorNameSplit[1]);
  }

  getReallyNiceName(sensorName) {
    switch(sensorName) {
      case "PM_SP_UG_2_5_DATA":
        return "PM 2.5";
      case "PM_SP_UG_10_DATA":
        return "PM 10";
      case "PM_SP_UG_1_DATA":
        return "PM 1";
      case "PM_SP_UG_0_5_DATA":
        return "PM 0.5";
      case "PM_SP_UG_0_3_DATA":
        return "PM 0.3";
      case "PM_SP_UG_0_1_DATA":
        return "PM 0.1";
      case "PM_SP_UG_0_05_DATA":
        return "PM 0.05";
      case "PM_SP_UG_0_01_DATA":
        return "PM 0.01";
      case "PM_AE_UG_2_5_DATA":
        return "PM 2.5 (AE)";
      case "PM_AE_UG_1_0_DATA":
        return "PM 1.0 (AE)";
      case "PM_SP_UG_10_0_DATA":
        return "PM 10.0";
      case "PM_AE_UG_10_0_DATA":
        return "PM 10.0 (AE)";
      case "PM_SP_UG_1_0_DATA":
        return "PM 1.0";
      case "PM_AE_UG_10_DATA":
        return "PM 10 (AE)";
      case "PM_AE_UG_1_DATA":
        return "PM 1 (AE)";
      case "PM_AE_UG_0_5_DATA":
        return "PM 0.5 (AE)";
      case "TVOC_DATA":
        return "VOC";
      case "ECO2_DATA":
        return "CO2";
      default:
        return sensorName;
    }
  }

  getSensorId(sensorKey) {
    var sensorNameSplit = sensorKey.split(":");
    return sensorNameSplit[0];
  }

  uncompressData(dataString) {
    const sensorData = JSON.parse(dataString);
    console.log(sensorData);

    const output = {};
    const arrayOutput = [];
    const sensorNames = Object.keys(sensorData);

    for (var i = 0; i < sensorNames.length; i++) {
      var sensorName = sensorNames[i];
      console.log(sensorName)
      var sensor = sensorData[sensorName];
      var uncompressedSensor = [];
      for (var j = 0; j < sensor.length; j++) {

        var sensorPoint = sensor[j];
        var time = sensorPoint["recordedTime"].split(".")[0];
        var value = sensorPoint["value"];
        uncompressedSensor.push({
          time: time,
          value: value,
          data: value,
          dateString: Moment(time).format("MM/DD/YYYY HH:mm:ss")
        });
      }
      output[sensorName] = uncompressedSensor;
      var sensorNiceName = this.getSensorNiceName(sensorName);
      arrayOutput.push({
        ChartName: sensorNiceName,
        data: uncompressedSensor,
        unit: this.getSensorUnit(sensorNiceName),
        units: this.getSensorUnit(sensorNiceName),
        name: sensorNiceName,
        Parent: sensorNiceName
      });
    }


    console.log("ArrayOutput:", arrayOutput)
    return arrayOutput;
  }


  async componentDidMount() {
    try {
      const session = await getSesssion(this.state.projectId);
      console.log("TestProjectResponse:", session)

      const { Name } = session.name;
      const isInitalLoading = false;
      var startTime = null;
      var endTime = null;

      startTime = new Date(session.starttime);
      endTime = new Date(session.endtime);
      if (!this.isValidDate(startTime)) {
        startTime = this.makeDate(session.starttime);
        endTime = this.makeDate(session.endtime);
      }

      this.setState({
        currentTime: Moment(startTime).format("MM/DD/YYYY HH:mm:ss"),
        chartCurrentTime: Moment(startTime).format("MM/DD/YYYY HH:mm:ss")
      });
      var CloudFrontLocation = session.cloudVideoLink;
      var dataContainers = this.uncompressData(session.data);
      console.log(dataContainers)
      this.createGraphDisplayer(dataContainers)
      dataContainers.forEach(element => {
        this.createProperDataContainer(element);
      });
      var arrayLength = dataContainers.length;
      var indexToRemove = -1;
      

      var realDataContainers = [];
      dataContainers.forEach(element => {
        if (element.data.length != 0) {
          realDataContainers.push(element);
        }
      });

      if (indexToRemove != -1) {
        dataContainers.splice(indexToRemove, 1);
      }
      dataContainers = realDataContainers;
      var currentDataPoints = [];

      this.setState({
        project: session,
        Name,
        startTime,
        endTime,
        dataContainers,
        currentDataPoints,
        CloudFrontLocation,
        s3StorageLocation: "https://vem-app-storage-ddefa4f211513-staging.s3.amazonaws.com/public/" + session.s3StorageLocation
      });
      this.populateCurrentDataPoints(dataContainers)
      
      this.createHighlightData(dataContainers)
      var standards = await this.getStandards();
      this.setState({
        standards,
        isInitalLoading,
        currentDataPoints
      });
    } catch (e) {
      alert("here" + e);
      console.log(e);
    }
  }

  getVideos(id) {
    return API.get("projects", `/video/${id}`);
  }

  async getStandards() {
    var builtInStandard = this.builtInStandards();
    var standards = [];

    builtInStandard.forEach(element => {
      standards.push(element);
    });
    return this.filterStandards(standards);
  }

  filterStandards(standards) {
    var newStandards = [];
    newStandards.push({
      Name: "",
      label: "No Standard",
      Value: 0,
      Type: "Default",
      Unit: "NONE"
    });

    if (!this.state.filterStandard) {
      standards.forEach(element => {
        newStandards.push(element);
      });
      return newStandards;
    }

    this.state.dataContainers.forEach(dataContainer => {

      // console.log(dataContainer)

      standards.forEach(standard => {
        if (standard.Unit.toLowerCase() === dataContainer.units.toLowerCase()) {
          newStandards.push(standard);
        }
      });
    });
    return newStandards;
  }

  builtInStandards() {
    var standards = [];
    standards.push({
      Name: "Test Standard",
      label: "Test Standard - Greater than 135",
      Value: 135,
      EquationType: "Greater",
      Unit: "Random",
      BuiltInStandard: "Yes",
      BrokenIcon: "🔢"
    });
    standards.push({
      Name: "Heart Rate Standard",
      label: "HR Standard - Greater than 100",
      Value: 100,
      EquationType: "Greater",
      Unit: "BPM",
      BuiltInStandard: "Yes",
      BrokenIcon: "💛"
    });
    standards.push({
      Name: "VOC Standard",
      label: "VOC Standard - Greater than 0",
      Value: 0,
      EquationType: "Greater",
      Unit: "BPM",
      BuiltInStandard: "Yes",
      BrokenIcon: "🎇"
    });
    standards.push({
      Name: "Particulate Standard",
      label: "Particulate Standard - Greater than 7000",
      Value: 7000,
      EquationType: "Greater",
      Unit: "p/L",
      BuiltInStandard: "Yes",
      BrokenIcon: "❄"
    });
    standards.push({
      Name: "CO2 Standard",
      label: "CO2 Standard - Greater than 5000",
      Value: 5000,
      EquationType: "Greater",
      Unit: "PPM",
      BuiltInStandard: "Yes",
      BrokenIcon: "⛽"
    });

    return standards;
  }

  async getDatabaseStandards() {
    var standards = await API.get("projects", `/potato`);

    standards.forEach(element => {
      element["label"] = element.Name;
    });
    return standards;
  }

  makeDate(dateObject) {
    return new Date(
      dateObject.year,
      dateObject.monthValue - 1,
      dateObject.dayOfMonth,
      dateObject.hour,
      dateObject.minute,
      dateObject.second
    );
  }

  tempMoveVideo(newCurrentTime) {
    var difference =
      (newCurrentTime.getTime() - this.state.startTime.getTime()) / 1000; //In Seconds
    this.state.tempPlayer.ref.current.seekTo(difference);
  }

  resetProject() {
    // this.tempMoveVideo();ic
    this.state.tempPlayer.ref.current.seekTo(0);
    this.setState({
      isPlaying: false,
      currentHighlight: null,
      lastHighLightIndex: -1,
      currentTime: Moment(this.state.startTime).format("MM/DD/YYYY HH:mm:ss")
    });

    this.populateCurrentDataPoints()
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  playProject() {
    if (!this.state.isPlaying) {
      this.highLightNewVersion()

    }

    var newCurrentTime = new Date(this.state.currentTime);
    if (newCurrentTime >= this.state.endTime) {
      return;
    }
    this.setState({
      isPlaying: !this.state.isPlaying
    });
  }

  initalizeCurrentDataPoints() {
    var currentDataPoints = [];
    this.state.dataContainers.forEach(dataContainer => {
      currentDataPoints.push({
        Name: dataContainer.name,
        Value: 0
      });
    });
    return currentDataPoints;
  }

  populateCurrentDataPoints() {
    var currentTime = new Date(this.state.currentTime);
    var currentPoints = this.state.currentDataPoints;

    for (var i = 0; i < this.state.dataContainers.length; i++) {
      for (var j = 0; j < this.state.dataContainers[i].labels.length; j++) {
        if (currentTime.getTime() == new Date(this.state.dataContainers[i].labels[j]).getTime()) {
          var foundOne = false;
          for (var index = 0; index < currentPoints.length; index++) {
            if (currentPoints[index].Name == this.state.dataContainers[i].name) {
              currentPoints[index].Value = this.state.dataContainers[i].sensorData[j];
              foundOne = true;
              break;
            }
          }
          if (foundOne == false) {
            currentPoints.push({
              Name: this.state.dataContainers[i].name,
              Value: this.state.dataContainers[i].sensorData[j]
            });
          }
          break;
        }
      }
    }

    console.log("Current Points: ", currentPoints)
    this.setState({
      currentDataPoints: currentPoints
    });
  }

  renderLoadingMessage() {
    return <h1> Loading Your Project... </h1>;
  }

  renderFloatingVideo() {
    return (<FloatingReactPlayer player={this.renderVideo()} ></ FloatingReactPlayer >)
  }
  renderVisualCard() {
    return (<Card
      title={"Current Time: " + this.state.chartCurrentTime}
      content={this.renderFloatingVideo()}

      width={450} height={300}
    />)
  }

  enableChart(event) {
    if (event.target != undefined && this.state.enabledCharts != undefined) {
      var currentStatus = event.target.checked
      // console.log(event.target)
      var chartName = event.target.getAttribute("chartname")
      console.log(chartName, currentStatus)
      // console.log(this.state.enabledCharts)
      var enabledCharts = this.state.enabledCharts;
      enabledCharts[chartName] = currentStatus
      this.setState({
        enabledCharts
      })
    }


  }

  renderIfEnabledChart(item) {
    var enabled = this.state.enabledCharts[item.name]
    if (!enabled) {
      return (<h1 test={this.state.enabledCharts[item.name].toString()}></h1>)
    }

    return (
      <Col test={this.state.enabledCharts[item.name].toString()} lg={12} xs={6} key={item.name}>
        <Card
          content={
            <DataDisplay
              data={this.createData(
                item.labels,
                item.sensorData,
                item.ChartName
              )}
              lineListener={this.state.chartCurrentTime}
              dataContainer={item}
            />
          }
        />
      </Col>
    )
  }

  toggleHighlight(event) {
    if (event.target != undefined && this.state.highlightGenerationData != undefined) {
      var currentStatus = event.target.checked
      console.log(event.target)
      var chartName = event.target.getAttribute("chartname")
      console.log(chartName, currentStatus)
      console.log(this.state.enabledCharts)
      var highlightGenerationData = this.state.highlightGenerationData;
      highlightGenerationData[chartName].enabled = currentStatus;
      this.setState({
        highlightGenerationData
      })
    }


  }

  onHighlightChange(event) {
    if (event.target != undefined && this.state.highlightGenerationData != undefined) {
      var chartName = event.target.getAttribute("chartname")
      console.log("target",event.target)
      console.log("event",event)
      // console.log(this.state.enabledCharts)
      var highlightGenerationData = this.state.highlightGenerationData;
      highlightGenerationData[chartName].value = event.target.value;
      console.log("highlightGenerationData",highlightGenerationData)
      console.log("Value",event.target.value)
      this.setState({
        highlightGenerationData
      })
    }
  }

  renderProject() {
    //console.log(this.state.videoPoint)
    return (
      <div className="ProjectWindow">
        <h1>
          <center> {this.state.Name} </center>
        </h1>
        <Grid>
          <Row>
            <Col lg={8}>
              {this.renderVisualCard()}
            </Col>
            <Col lg={4} xs={3}>
              <Card
                title="Controls"
                content={this.renderSideStuff()}
              />

            </Col>
            <Col lg={4} xs={3}>
              {this.renderHighlightEntry()}
            </Col>

          </Row>
          <SensorBar
            onHighlightChange={this.onHighlightChange.bind(this)} toggleHighlight={this.toggleHighlight.bind(this)} currentDataPoints={this.state.currentDataPoints} handleEnableChart={this.enableChart.bind(this)} enabledCharts={this.state.enabledCharts} highlightGenerationData={this.state.highlightGenerationData} />
          <Row > </Row>
        </Grid>

        < div
          style={{
            height: "1500px"
          }}
        >
          <Grid>
            <Row>
              {this.state.dataContainers.map(item => (
                <div>
                  {this.renderIfEnabledChart(item)}
                </div>
              ))}
            </Row>
          </Grid>
        </div>
        <br />
        <div
          style={{
            "fontSize": "1px"
          }}
          className="Footer"
        />

      </div >
    );
  }

  renderBrokenStandardSideStuff() {
    var listOfBrokenStrings = [];
    if (listOfBrokenStrings.length > 0) {
      const brkStandardStuff = listOfBrokenStrings.map(item => (
        <BrokenStandardText
          HoverText={item.HoverText}
          Icon={item.Icon}
        ></BrokenStandardText>
      ));
      // console.log(brkStandardStuff);
      return brkStandardStuff;
    }
    return "";
  }


  GoToNextHighlight() {
    this.setState({
      skipToNextHighlight: true
    });
  }

  updateLagTime(update) {
    console.log(update.target.value);
    var lagTime = Number(update.target.value);
    this.setState({
      "lagTime": lagTime
    })
  }

  renderSideStuff() {
    return (
      <React.Fragment>
        <ProjectStandardScreen dataContainers={this.state.dataContainers} oldLagTimeMethod={this.updateLagTime.bind(this)} />
        <div>
          <h6>Lag Time:</h6>
          <input type="text" value={this.state.lagTime} onChange={this.updateLagTime.bind(this)}></input>
        </div>
        <Button variant="secondary" onClick={() => this.playProject()}>
          Play / Pause
        </Button>
        <Button variant="secondary" onClick={() => this.resetProject()}>
          Reset
        </Button>
        <Button variant="secondary" onClick={() => this.GoToNextHighlight()}>
          Next
        </Button>
        <br></br>
        <br></br>
        {this.renderBrokenStandardSideStuff()}
      </React.Fragment>
    );
  }
  render() {
    return (
      <div className="Home">
        {this.state.isInitalLoading
          ? this.renderLoadingMessage()
          : this.renderProject()}
      </div>
    );
  }

  renderStandards() {
    return (
      <Select
        value={this.state.selectedStandands}
        onChange={this.handleStandardChange.bind(this)}
        options={this.state.standards}
      />
    );
  }

  createProperDataContainer(dataContainer) {
    try {
      dataContainer.sensorData = [];
      dataContainer.labels = [];

      if (dataContainer.name == null) {
        dataContainer.name = dataContainer.Name;
      }

      dataContainer.data.forEach(element => {
        dataContainer.sensorData.push(element.data);
        dataContainer.labels.push(element.dateString);
      });
      dataContainer.ChartName =
        dataContainer.name + " in " + dataContainer.units;
    } catch (e) {
      console.log(e);
    }
  }

  loadTabbedChart() {
    return (<TabbedCharts />)
  }

  renderCharts() {
    return (
      <React.Fragment>
        <Card content={this.loadTabbedChart()} />


      </React.Fragment>
    )
  }

  reactPlayerRender(fileLocation) {
    return (

      <h1>HELLO CAN YOU SEE ME</h1>
    )
  }


  renderVideo() {
    var fileLocation = this.state.s3StorageLocation;
    // console.log("Enabling Pip",);
    const temp = (
      <ReactPlayer
        ref={this.state.player}
        muted
        playing={this.state.isPlaying}
        url={fileLocation}
        pip={true}
      />



    );
    this.state.tempPlayer = temp;


    return temp;
  }


  
  createData(labels, sensorData, name) {
    const data = {
      labels: labels,
      animation: true,
      datasets: [
        {
          type: "line",
          label: name,
          fill: true,
          data: sensorData,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10
        }
      ]
    };
    return data;
  }
  renderItems() {
    if (this.state.currentDataPoints != null) {
      const listItems = this.state.currentDataPoints.map(item => (
        <p class="SensorName" key={item}>
          <strong>{item.Name}:</strong> {item.Value}
        </p>
      ));
      return listItems;
    }
  }
}
