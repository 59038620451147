import React, { Component } from "react";
import Pdf from "../assets/pdfs/CapabilityStatement.pdf";
import { Document, Page } from "react-pdf";
class BrokenStandardText extends Component {
  render() {
    return (
      <React.Fragment>
        <span title={this.props.HoverText}>{this.props.Icon}</span>
      </React.Fragment>
    );
  }
}

export default BrokenStandardText;
