import { Storage } from "aws-amplify";
import uuid from "uuid";

export async function s3Upload(file) {
  const filename = await CreateFileName(file)
  console.log('FileName: '+ filename)
  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type
  });

  return stored.key;
}
export async function s3RawUpload(file, fileName) {
  console.log(fileName)
  if (!fileName){
    fileName = CreateFileName(file)
  }
  const stored = await Storage.put(fileName, file, {
    contentType: file.type
  });
  console.log(stored)
  return "public/" + stored.key;
}

export async function VideoUpload(file, fileName){
  console.log("in here")
  const stored = await Storage.vault.put(fileName, file, {
    bucket: 'vemvideoondemand-source-20cs1smywjn8',
    contentType: file.type
  });
  return stored.key;
}


export async function CreateFileName(file){
  return `${uuid.v1()}/${Date.now()}-${uuid.v1()}-${file.name}`;
}