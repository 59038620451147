import React, { Component } from "react";
import Card from "../components/Card/Card"

export class StandardDisplay extends Component {


    render(){

  
     return(
        <Card title={"Hello"}></Card>
  
      )
    }
  }
  
  export default StandardDisplay;