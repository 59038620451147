import { Line } from "react-chartjs-2";
import React, { Component, useState } from "react";
import {
    FormGroup,
    FormControl,
    Grid,
    Button,
    Container,
    Row,
    Col
} from "react-bootstrap";
export class LagBoxSection extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = { "lagTime": 5 }

    }

    handleChange(event) {
        const lagTimAsNum = Number(event.target.value);
        this.setState({
            lagTime: event.target.value
        });

        this.props.updateLagTime(lagTimAsNum)
    }

    printLagTime() {
        // console.log(this.state.lagTime)

    }

    getLagTime() {
        return this.state.lagTime;
    }

    render() {
        // console.log("Props In LagBoxSection", this.props);
        return (
            <div>
                <h6>Lag Time:</h6>
                <input type="text" value={this.state.lagTime} onChange={this.handleChange.bind(this)}></input>
            </div>
        );
    }
}

export default LagBoxSection;
