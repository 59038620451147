import React, { Component } from 'react';
import { Grid, Col, Row } from 'react-bootstrap'
import { Card } from '../components/Card/Card'
import { Auth } from 'aws-amplify';
class Account extends Component {

    state = {}

    componentDidMount() {
        this.setState({
            loading: true,
            user: null
        })

        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => this.setState({
            firstname: user.username,
            loading: false
        }))
            .catch(err => console.log(err));
    }

    PersonalInformationContent() {
        return (
            <React.Fragment>
                <h5>Name: {"Kyle Fischer"}</h5>
                <h5>Email: {"kylefischer1995@gmail.com"}</h5>
                <h5>Phone : {"812-631-0355"}</h5>
            </React.Fragment>

        )
    }

    AccountInformationContent() {
        return (
            <React.Fragment>
                <h5>Tier: {"Free Demo Tier"}</h5>
            </React.Fragment>

        )
    }


    renderLoading() {
        return (<React.Fragment>
            <h1>LOADING</h1>
        </React.Fragment>)
    }

    render() {
        let display;
        if (this.state.user === undefined) {
            display = this.renderLoading();
        } else {
            display = this.renderLoaded();
        }

        return (
            <React.Fragment>{display}</React.Fragment>

        );
    }

    renderLoaded() {

        return (
            <div>

                <Grid>
                    <Row>
                        <Card lg={12} title={"Personal Information"} content={this.PersonalInformationContent()} />
                        <Card lg={12} title={"Account Information"} content={this.AccountInformationContent()} />
                    </Row>

                </Grid>
            </div>
        );
    }
}

export default Account;