import React, { Component, useState } from "react";
import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import Card from "../Card/Card";

export class FloatingReactPlayer extends Component {
    constructor(props) {
        super(props);

    }

    handleChange(event) {

    }


    render() {
        return (
            <Draggable>
                <ResizableBox width={450} height={300} style={{ "zIndex": 999 }}>

                    {this.props.player}
                </ResizableBox>
            </Draggable>
        );
    }
}

export default FloatingReactPlayer;