import React, { Component } from "react";
import {
  FormGroup,
  FormControl,

  Button,
  Grid,
  Row,
  Col,
  Modal
} from "react-bootstrap";
import Card from "../components/Card/Card";
import StandardCard from "../components/StandardCard";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./Standards.css";
import { API } from "aws-amplify";
import {
  s3Upload,
  s3RawUpload,
  CreateFileName,
  VideoUpload
} from "../libs/awsLib";
import VideoDisplay from "../components/VideoDisplay";
import TimePicker from "rc-time-picker";
import { format } from "path";
import { Link } from "react-router-dom";
import NewStandard from "../containers/NewStandard";

export default class Standards extends Component {
  constructor(props) {
    super(props);

    this.dataFiles = null;
    this.VideoFile = null;
    this.state = {
      isLoading: true,
      projectName: "",
      VideoStartTime: "",
      standards: []
    };
  }

  validateForm() {
    return this.state.projectName.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleDataFileChange = event => {
    console.log("DATA FILES");
    this.dataFiles = event.target.files[0];
    console.log(this.dataFiles);
  };

  uploadVideoFiles() {
    console.log("Upload VIdeo");
  }

  createNewStandard() {
    this.props.history.push("/standards/new");
  }

  builtInStandards() {
    var standards = [];
    standards.push({
      Name: "Test Standard",
      label: "Test Standard - Greater than 135",
      Value: 135,
      EquationType: "Greater",
      Unit: "Random",
      BuiltInStandard: "Yes",
      BrokenIcon: "🔢"
    });
    standards.push({
      Name: "Heart Rate Standard",
      label: "HR Standard - Greater than 110",
      Value: 110,
      EquationType: "Greater",
      Unit: "BPM",
      BuiltInStandard: "Yes",
      BrokenIcon: "💛"
    });
    standards.push({
      Name: "VOC Standard",
      label: "VOC Standard - Greater than 0",
      Value: 1,
      EquationType: "Greater",
      Unit: "PPB",
      BuiltInStandard: "Yes",
      BrokenIcon: "🎇"
    });
    standards.push({
      Name: "Particulate Standard",
      label: "Particulate Standard - Greater than 7000",
      Value: 7000,
      EquationType: "Greater",
      Unit: "p/L",
      BuiltInStandard: "Yes",
      BrokenIcon: "❄"
    });
    standards.push({
      Name: "CO2 Standard",
      label: "CO2 Standard - Greater than 5000",
      Value: 5000,
      EquationType: "Greater",
      Unit: "PPM",
      BuiltInStandard: "Yes",
      BrokenIcon: "⛽"
    });

    return standards;
  }

  getDatabaseStandards() {
    return API.get("projects", "/potato");
  }

  async componentDidMount() {
    try {
      this.state.isLoading = true;
      var databaseStandards = await this.getDatabaseStandards();
      var standards = this.builtInStandards();
      databaseStandards.forEach(element => {
        element["BuiltInStandard"] = "No";
        standards.push(element);
      });
      this.setState({
        standards: standards,
        databaseStandards: databaseStandards,
        isLoading: false
      });

      console.log(databaseStandards);
    } catch (e) {
      alert("here" + e);
    }
  }

  standardContent(standard) {
    return (
      <div>
        <h5>Value: {standard.Value}</h5>
        <h5>Equation Type: {standard.EquationType}</h5>
        <h5>Unit: {standard.Unit}</h5>
        <h5>Built in: {standard.BuiltInStandard}</h5>
        <h5>Standard Broken Icon: {standard.BrokenIcon}</h5>
      </div>
    );
  }

  renderStandards() {
    const listItems = this.state.standards.map(item => (
      <React.Fragment>
        <Col lg={3}>
          <Card
            title={<center>{item.Name}</center>}
            content={this.standardContent(item)}
          />
        </Col>
      </React.Fragment>
    ));
    return listItems;
  }

  renderLoadingScreen() {
    return (
      <div className="Standards">
        <center>
          <h1>Loading</h1>
        </center>
      </div>
    );
  }

  renderModal(content) {
    return (
      <>
        <Button
          variant="primary"
          className="btn btn-info btn-lg"
          onClick={() => this.showPrideAndJoy()}
        >
          Create a New Standard
        </Button>
        <Modal
          show={this.state.show}
          onHide={() => this.updateModalShowState(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create a New Standard</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {<NewStandard parentCallback={() => this.callBack()} />}
          </Modal.Body>
        </Modal>
      </>
    );
  }
  callBack() {
    this.updateModalShowState(false);
    window.location.reload();
  }

  updateModalShowState(stuff) {
    this.setState({
      show: stuff
    });
  }

  showPrideAndJoy() {
    console.log("here");
    this.setState({
      show: true
    });
  }

  renderStandardPage() {
    return (
      <div className="Standards">
        <center>
          <h1 className="pageTitle">Standards</h1>
        </center>
        <center>{this.renderModal()}</center>
        <br />
        <br />
        <Grid>
          <Row>{this.renderStandards()}</Row>
        </Grid>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.state.isLoading
          ? this.renderLoadingScreen()
          : this.renderStandardPage()}
      </div>
    );
  }
}
