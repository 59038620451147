import { Line } from "react-chartjs-2";
import React, { Component, useState } from "react";
import DataDisplay from "../DataDisplay";
import {
    FormGroup,
    FormControl,
    Button,
    Container,
    Row,
    Col, Tab, Tabs, Sonnet
} from "react-bootstrap";
export class TabbedCharts extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = { "lagTime": 5 }

    }

    handleChange(event) {
        const lagTimAsNum = Number(event.target.value);
        this.setState({
            lagTime: event.target.value
        });

        this.props.updateLagTime(lagTimAsNum)
    }

    printLagTime() {
        console.log(this.state.lagTime)

    }

    getLagTime() {
        return this.state.lagTime;
    }

    render() {
        console.log("Props in Tabbed Charts", this.props);
        return (
            <Tabs defaultActiveKey="createNew" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="createNew" title="+">
                    <h1>+</h1>
                </Tab>
            </Tabs>
        );
    }
}

export default TabbedCharts;
