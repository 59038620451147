import { Line } from "react-chartjs-2";
import React, { Component, useState } from "react";
import LagBoxSection from "./LagBoxSection";
import {
  FormGroup,
  FormControl,
  Grid,
  Button,
  Container,
  Row,
  Col
} from "react-bootstrap";
export class ProjectStandardScreen extends Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      "highlightMode": false,
      "lagTime": 5,
      "selectedStandards": []
    }
    this.lagTimeBox = null;
  }



  updateStandard() {

  }

  updateLagTime() {
    this.setState({
      hasBeenClicked: true
    })
  }

  toggleHighlights() {
    // console.log("Lag Time", this.state.lagTime)
    this.setState({
      highlightMode: !this.state.highlightMode
    });
    // console.log("High Light Mode:", this.state.highlightMode)

  }

  hardCodedStandards() {
    var standards = [];
    standards.push({
      Name: "Test Standard",
      label: "Test Standard - Greater than 135",
      Value: 135,
      EquationType: "Greater",
      Unit: "Random",
      SensorName: "12321",
      BuiltInStandard: "Yes",
      BrokenIcon: "🔢"
    });
    standards.push({
      Name: "Heart Rate Standard",
      label: "HR Standard - Greater than 100",
      Value: 100,
      EquationType: "Greater",
      Unit: "BPM",
      BuiltInStandard: "Yes",
      SensorName: "12321",
      BrokenIcon: "💛"
    });
    standards.push({
      Name: "VOC Standard",
      label: "VOC Standard - Greater than 0",
      Value: 0,
      EquationType: "Greater",
      Unit: "BPM",
      BuiltInStandard: "Yes",
      SensorName: "12321",
      BrokenIcon: "🎇"
    });
    standards.push({
      Name: "Particulate Standard",
      label: "Particulate Standard - Greater than 7000",
      Value: 7000,
      EquationType: "Greater",
      Unit: "p/L",
      BuiltInStandard: "Yes",
      SensorName: "PMS5003 - PM1.0 ug/m3",
      BrokenIcon: "❄"
    });
    standards.push({
      Name: "CO2 Standard",
      label: "CO2 Standard - Greater than 5000",
      Value: 5000,
      EquationType: "Greater",
      Unit: "PPM",
      SensorName: "12321",
      BuiltInStandard: "Yes",
      BrokenIcon: "⛽"
    });

    return standards;

  }

  loadInitalState() {
    this.setState({
      highlightMode: true
    });
  }




  generateHighlights() {
    console.log("Generate Highlights")
    var masterList = {}
    this.setState({
      selectedStandards: this.hardCodedStandards()
    })
    var standards = this.hardCodedStandards()
    standards.forEach(standard => {
      var standardContainers = this.getDataContainerForStandard(standard);
      var brokenStandardPoints = {}
      standardContainers.forEach(dataContainer => {

      });
    });
  }

  generateStandardHighlightReel() {

  }

  getDataContainerForStandard(standard) {
    var dataContainers = this.props.dataContainers;
    var standardContainers = []
    dataContainers.forEach(dataContainer => {
      if (standard.SensorName.localeCompare(dataContainer.name) == 0) {
        standardContainers.push(dataContainer)
      }
    });

    return standardContainers;
  }
  onLagTimeUpdate(lagTime) {
    console.log(lagTime)
    this.setState({
      "lagTime": lagTime
    })
    this.props.oldLagTimeMethod(lagTime);
  }

  createLagTimeBox() {
    return <LagBoxSection lagTime={this.state.lagTime} updateLagTime={this.onLagTimeUpdate.bind(this)} />;
  }
  getLagTime() {
    console.log("Our Lag Time:", this.state.lagTime)
  }

  render() {
    // console.log("Props", this.props)

    return (
      <div>
        {/* < Button variant="secondary" onClick={() => this.toggleHighlights()}> Toogle Highlights</Button >
        <Button variant="secondary" onClick={() => this.generateHighlights()}>Generate Highlights</Button> */}
        <br></br>
      </div >
    );
  }
}

export default ProjectStandardScreen;
