import React, { Component } from "react";
import { Modal } from "react-bootstrap";

class VEMModal extends Component {
  state = {};
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.content}</Modal.Body>
        <Modal.Footer>{this.props.footer}</Modal.Footer>
      </Modal>
    );
  }
}

export default VEMModal;
