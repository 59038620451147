// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const StandardComparisonType = {
  "EQUAL": "EQUAL",
  "GREATER": "GREATER",
  "GREATER_EQUAL": "GREATER_EQUAL",
  "LESSER": "LESSER",
  "LESSER_EQUAL": "LESSER_EQUAL"
};

const { Sesssion, Standards, TestProject } = initSchema(schema);

export {
  Sesssion,
  Standards,
  TestProject,
  StandardComparisonType
};