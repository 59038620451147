import React, { ReactFragment, Component } from "react";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Card from "../components/Card/Card";
import { API, Auth, graphqlOperation, Amplify } from "aws-amplify";
import { Link } from "react-router-dom";
import Welcome from "../components/BasicHeader";
import { Grid, Row, Col, Modal } from "react-bootstrap";
import Button from "../components/CustomButton/CustomButton";
import InformationCardContent from "../components/informationCardContent";
import logo from "../assets/img/VEMLogo.svg";
import NewProject from "../containers/NewProject";

import {getAllSesssions, getSesssion} from "../services/projectService";

import { DataStore } from '@aws-amplify/datastore';

import "./Home.css";
import { TestProject } from "../models";

let Step1Text =
  "Using our Mobile Device or Laptop capture, you get an accurate representation of data/video and can record multiple sensors at the same. Our Mobile Capture system is very portable and can fit in your hands or the back of a backpack.";
let Step1Image =
  "https://images.squarespace-cdn.com/content/v1/5a3825ea6f4ca388d45457e3/1513889677511-BSL9B476GV8DY8N99BH8/ke17ZwdGBToddI8pDm48kDHPSfPanjkWqhH6pl6g5ph7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0mwONMR1ELp49Lyc52iWr5dNb1QJw9casjKdtTg1_-y4jz4ptJBmI9gQmbjSQnNGng/IMG_1828+%281%29.JPG?format=750w";
let Step2Text =
  "After collecting data, you bring the data over to your computer. Using our cross platform application, you import your data from your capture session. Then by using the built in standards or custom standards, you can quickly identify the moments of high exposure.";
let Step2Image =
  "https://images.squarespace-cdn.com/content/v1/5a3825ea6f4ca388d45457e3/1513896581182-W27XIXTL9VNQRA82024A/ke17ZwdGBToddI8pDm48kDHPSfPanjkWqhH6pl6g5ph7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0mwONMR1ELp49Lyc52iWr5dNb1QJw9casjKdtTg1_-y4jz4ptJBmI9gQmbjSQnNGng/IMG_0961.JPG?format=750w";
let Step3Text =
  "You can use our built in HTML report system which will generate a report for you to show your findings or you can play back the highlight points on our software. Then using this knowledge, you will be able to go fix the issues that currently exist in your work area.";
let Step3Image =
  "https://images.squarespace-cdn.com/content/v1/5a3825ea6f4ca388d45457e3/1513896753738-XCOCYDECYTAK3LW139X6/ke17ZwdGBToddI8pDm48kAXHjrggya5971KAG017bX5Zw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpy6taBUC-O-Nf30tkYTMJJA0eIrKmvx97SJO1b_-hzcwTWxXWUdGfAmtS_K9mpFwDk/ReportMockUp.PNG?format=750w";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      projects: [],
      firstName: "",
      timer: null
    };
  }

  GenerateCard(text, image) {
    return (
      <InformationCardContent
        leftSide={<span className="leftSideText">{text}</span>}
        rightSide={<img className="rightSideImage" src={image} />}
      />
    );
  }

  async tick() {
    console.log("Refreshed Project List!");
    const projects = await this.projects();
    this.setState({ projects });
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const projects = await this.projects();

      let user = await Auth.currentAuthenticatedUser();
      const { attributes } = user;
      this.state.firstName = attributes["given_name"];
      if (this.state.timer == null) {
        console.log("timers running");
        this.setState({
          timer: setInterval(this.tick.bind(this), 60000)
        });
      }
      this.setState({
        projects
      });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  async projects() {
    return await getAllSesssions();

  }

  renderWelcomeMessage() {
    return <Welcome firstName={this.state.firstName} />;
  }

  renderExampleCard() {
    return (
      <div>
        <iframe
          width="444"
          height="250"
          src="https://www.youtube.com/embed/FNMFLrQzocY"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
    );
  }

  renderLander() {
    return (
      <div className="lander">
        <img src={logo} />
        <h4>
          Video Exposure Monitoring (VEM) is the process of recording sensor
          data and capturing video footage and stitching them together! Then
          when playing back, you are able to quickly identify the points of
          exposures using a system of Government or User defined standards.
        </h4>
        <div>
          <Link to="/login" className="btn btn-info btn-lg">
            Login
          </Link>
          <Link to="/signup" className="btn btn-success btn-lg">
            Signup
          </Link>
        </div>
        <div>
          <Grid>
            <Row>
              <Col lg={6}>
                <Card
                  title={"Step 1: Capture"}
                  content={this.GenerateCard(Step1Text, Step1Image)}
                />
              </Col>
              <Col lg={6}>
                <Card
                  title={"Step 2: Identifiy"}
                  content={this.GenerateCard(Step2Text, Step2Image)}
                />
              </Col>
              <Col lg={6}>
                <Card
                  title={"Step 3: Report"}
                  content={this.GenerateCard(Step3Text, Step3Image)}
                />
              </Col>
              <Col lg={6}>
                <Card
                  title={"Example: Using VEM to Expose Exposure to Heavy Metal"}
                  content={this.renderExampleCard()}
                />
              </Col>
            </Row>

            <Card
              title={"More Infromation on VEM"}
              content={
                <React.Fragment>
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/AJOxtBuhov4"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  />
                </React.Fragment>
              }
            />
          </Grid>
        </div>
      </div>
    );
  }

  renderModal(content) {
    return (
      <>
        <Button
          variant="primary"
          className="btn btn-info btn-lg"
          onClick={() => this.showPrideAndJoy()}
        >
          Create a New Project
        </Button>
        <Modal
          show={this.state.show}
          onHide={() => this.updateModalShowState(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create a New Project</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {<NewProject parentCallback={() => this.callBack()} />}
          </Modal.Body>
        </Modal>
      </>
    );
  }

  callBack() {
    this.updateModalShowState(false);
    window.location.reload();
  }

  createProject(project) {

  }

  createThing(project) {
    if (project == null) {
      return <ReactFragment></ReactFragment>
    }
    try {
      var projectName = ""

      if (typeof (project.name) !== 'undefined') {
        projectName = project.name.trim().split("\n")[0]
      } else {
        projectName = "Corrupted Project (Please Reupload Data)"
      }

      var status = project.status ? "Complete" : "Incomplete"

      return (
        <LinkContainer
          key={project.id}
          to={`/project/${project.id}`}
        >
          <ListGroupItem
            header={<strong>{projectName}</strong>}
          >
            <p>{"Status: " + status}</p>
            <p>
              {"Created Date: " +
                new Date(project.starttime).toLocaleDateString() +
                " " +
                new Date(project.starttime).toLocaleTimeString()}
            </p>
          </ListGroupItem>
        </LinkContainer>
      )
    } catch (error) {
      console.log(error)
      return <ReactFragment></ReactFragment>
    }

  }

  renderProjectList(projects) {
    console.log(projects)
    projects.sort(function (a, b) {
      var date1 = new Date(a.starttime);
      var date2 = new Date(b.starttime);
      return date2 - date1;
    });
    const listItems = projects.map(project => this.createThing(project));
    return listItems;
  }


  renderTopStats() {
    var totalCount = this.state.projects.length;
    var DoneProcessingCount = 0;
    this.state.projects.forEach(element => {
      if (element.ProcessingStage !== "Finished Processing" || element.ProcessingStage == "Beta Procesing Done") {
        DoneProcessingCount++;
      }
    });
    return (
      <div>
        <Grid>
          <Row>
            <Col lg={4}>
              <Card
                title={"Processing Projects"}
                content={DoneProcessingCount}
              />
            </Col>
            <Col lg={4}>
              <Card
                title={"Processed Projects"}
                content={totalCount - DoneProcessingCount}
              />
            </Col>
            <Col lg={4}>
              <Card title={"Total Projects"} content={totalCount} />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }

  renderControlsContents() {
    return (
      <Button href="/" round fill bsStyle="info">
        Refresh
      </Button>
    );
  }

  showPrideAndJoy() {
    console.log("here");
    this.setState({
      show: true
    });
  }
  callBack() {
    this.updateModalShowState(false);
    window.location.reload();
  }

  updateModalShowState(stuff) {
    this.setState({
      show: stuff
    });
  }
  renderLoggedInPage() {
    return (
      <div>
        {this.renderTopStats()}
        <center>{this.renderModal()}</center>
        <br />
        {this.renderProjectList(this.state.projects)}
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated
          ? this.renderLoggedInPage()
          : this.renderLander()}
      </div>
    );
  }
}
