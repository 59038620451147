import React, { Component } from "react";

class DeviceComponent extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <h2>{this.props.StepNumber}</h2>
        <React.Fragment className="leftSide">
          {this.props.leftSide}
        </React.Fragment>
        <React.Fragment className="rightSide">
          {this.props.rightSide}
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default DeviceComponent;
