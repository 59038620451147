import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Download from "./components/Download";
import ResetPassword from "./containers/ResetPassword";

import Signup from "./containers/Signup";
import Devices from "./containers/Devices";
import NewProject from "./containers/NewProject";
import Project from "./containers/Project";
import Standards from "./containers/Standards";
import NewStandard from "./containers/NewStandard";
import NewDevice from "./containers/NewDevice";

import Account from "./containers/Account";
import Settings from "./containers/Settings";

export default ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <UnauthenticatedRoute
      path="/home"
      exact
      component={Home}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/login"
      exact
      component={Login}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/signup"
      exact
      component={Signup}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/login/reset"
      exact
      component={ResetPassword}
      props={childProps}
    />

    <UnauthenticatedRoute
      path="/statement"
      exact
      component={Download}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/account"
      exact
      component={Account}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/settings"
      exact
      component={Settings}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/devices"
      exact
      component={Devices}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/project/new"
      exact
      component={NewProject}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/devices/new"
      exact
      component={NewDevice}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/project/:id"
      exact
      component={Project}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/standards"
      exact
      component={Standards}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/standards/new"
      exact
      component={NewStandard}
      props={childProps}
    />

    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);
