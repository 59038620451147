/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://o6sg5gwdlvevhpsd7mjmjjcnuy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-pepw4x3evfhppjpuai5jkzbmem",
    "aws_cognito_identity_pool_id": "us-east-1:86255e3f-00d6-4efb-881e-bb7abef7dba0",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_saj8WlnsG",
    "aws_user_pools_web_client_id": "2lvd3srn83cdcqhd7am7ag4rl9",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER",
        "NAME",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "vem-app-storage-ddefa4f211513-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
