import { API, graphqlOperation, Auth } from "aws-amplify"
import { ListFormat } from "typescript";
import { TestProject } from "../models";

function _getSesssion(id) {
    return `query getSesssion {
    getSesssion(id: "${id}"){
        id
        userId
        name
        status
        description
        createdAt
        projectJson
        cloudVideoLink
        starttime
        endtime
        data
        s3StorageLocation
        }
    }`;
}


export const getSesssion = async (projectId) => {
    const testProjectResponse = await API.graphql(graphqlOperation(_getSesssion(projectId)));
    console.log(testProjectResponse);
    return testProjectResponse.data.getSesssion;
}

const listSesssions = `query listSesssions {
  listSesssions {
    items{
        id
        name
        description
        starttime
        endtime
    }
  }
}`;

function _getUsersSesssions(userId) {
    return `query getUsersSesssions {
    getUsersSesssions(userId: "${userId}"){
        id
        name
        description
        createdAt
        projectJson
        cloudVideoLink
        }
    }`;
}


export const getAllSesssions = async () => {
    const user = await Auth.currentAuthenticatedUser();
    console.log("user", user);
    const testProjectResponse = await API.graphql(graphqlOperation(listSesssions));
    // new list of testprojects for user
    console.log(testProjectResponse);
    const testProjects = testProjectResponse.data.listSesssions.items;
    console.log("testProjects", testProjects)
    const userTestProjects = testProjects.filter(testProject => testProject.userId === user.username);
    console.log("userTestProjects", userTestProjects);
    return testProjects;

}

export const convertStringJsonToObject = (stringJson) => {
    return JSON.parse(stringJson.toString())
}