import React, { Component, useState } from "react";
import { Grid, Col, Row, Modal, Button } from "react-bootstrap";
import { Card } from "../components/Card/Card";
import { API } from "aws-amplify";
import VEMModal from "../components/Modal/VEMModal";
import File from "../assets/files/RegistrationData.RegVem";
import { Link } from "react-router-dom";
import DeviceComponent from "../components/DeviceComponent";
import NewDevice from "../containers/NewDevice";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Centered Modal</h4>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default class Devices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      isLoading: true,
      show: false
    };
  }

  async componentDidMount() {
    try {
      this.state.isLoading = true;
      var devices = await this.getDevices();

      this.setState({
        devices: devices,
        isLoading: false
      });
      console.log(devices);
    } catch (e) {
      alert("here" + e);
    }
  }

  CreateDeviceCard(deviceInformation) {
    return (
      <Col lg={6}>
        <Card
          title={deviceInformation.DeviceName}
          content={this.CreateDeviceContent(deviceInformation)}
          stats={this.DeviceDownload()}
        />
      </Col>
    );
  }

  DeviceDownload() {
    return (
      <a href={File} download className="btn btn-info btn-sm">
        Download Registration File
      </a>
    );
  }

  getDevices() {
    return API.get("projects", "/devices");
  }

  CreateDeviceContent(deviceInformation) {
    return (
      <React.Fragment>
        <h5>Owner: {deviceInformation.Owner}</h5>
        <h5>DeviceID: {deviceInformation.DeviceID}</h5>
        <h5>
          Created On: {new Date(deviceInformation.CreatedOn).toLocaleString()}
        </h5>
        <h5>
          Expiration Date:{" "}
          {new Date(deviceInformation.ExpirationDate).toLocaleString()}
        </h5>
      </React.Fragment>
    );
  }
  renderLoading() {
    return (
      <React.Fragment>
        <h1>LOADING</h1>
      </React.Fragment>
    );
  }

  showPrideAndJoy() {
    console.log("here");
    this.setState({
      show: true
    });
  }

  handleOnModalClose() {
    console.log("Here");
    this.SetState({
      showCreateDeviceModal: false
    });
  }

  render() {
    let display;
    display = this.renderLoaded();

    return <React.Fragment>{this.renderLoaded()}</React.Fragment>;
  }

  updateModalShowState(stuff) {
    this.setState({
      show: stuff
    });
  }

  renderModal(content) {
    return (
      <>
        <Button
          variant="primary"
          className="btn btn-info btn-lg"
          onClick={() => this.showPrideAndJoy()}
        >
          Register New Device
        </Button>
        <Modal
          show={this.state.show}
          onHide={() => this.updateModalShowState(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Register a new Device</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {<NewDevice parentCallback={() => this.callBack()} />}
          </Modal.Body>
        </Modal>
      </>
    );
  }

  callBack() {
    this.updateModalShowState(false);
    window.location.reload();
  }

  renderDevices() {
    const listItems = this.state.devices.map(item => (
      <React.Fragment>{this.CreateDeviceCard(item)}</React.Fragment>
    ));
    return listItems;
  }

  renderLoaded() {
    return (
      <div>
        <center>
          <h1 className="title">Registered Devices</h1>
          <center>{this.renderModal()}</center>{" "}
        </center>
        <br />
        <br />

        <Grid>
          <Row>{this.renderDevices()}</Row>
        </Grid>
      </div>
    );
  }
}
