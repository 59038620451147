import React, { Component, useState } from "react";
import { Table } from "react-bootstrap"
import Card from "../Card/Card";

export class SensorBar extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = { "hi": 5 }

    }

    componentDidMount() {

    }
    componentWillUnmount() {
    }


    renderCol(item) {
        var newName = item.Name;
        return (
            <td key={newName}>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>
                                {newName}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Current Value: {item.Value}</td>
                        </tr>
                        <tr>
                            <td>

                                <input type="checkbox"
                                    name="showChart"
                                    id={newName + "showChart"}
                                    chartname={newName}
                                    onChange={this.props.handleEnableChart}
                                    checked={this.props.enabledCharts[item.Name]}
                                />
                                <label for={item.Name + "showChart"}>
                                    Show Chart?
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="checkbox"
                                    name="enableHighLight"
                                    chartname={newName}
                                    id={newName + "enableHighlight"}
                                    onChange={this.props.toggleHighlight}
                                    checked={this.props.highlightGenerationData[newName].enabled} />
                                <label for={newName + "enableHighlight"}>
                                    Enable Alert for Greater Than
                                </label>
                                <input type="number"
                                    disabled={!this.props.highlightGenerationData[newName].enabled}
                                    chartname={newName}
                                    name="greaterThanNumber"
                                    onChange={this.props.onHighlightChange}
                                    value={this.props.highlightGenerationData[newName].value} /></td>
                        </tr>
                    </tbody>
                </Table>
            </td>

        )
    }

    render() {
        this.props.handleEnableChart("test")
        return (
            <React.Fragment>
                <Card
                    title="Sensor Information"
                    content={
                        <Table responsive >
                            <tbody>
                                {this.props.currentDataPoints.map(item => (
                                    this.renderCol(item)
                                ))}
                            </tbody>

                        </Table>
                    }>

                </Card>

            </React.Fragment>
        );
    }
}

export default SensorBar;
